import React, { useState, useEffect, Fragment, useLayoutEffect } from 'react';
import Header from "../header";
import { useNavigate } from 'react-router-dom'
import RestaurantTable from './RestaurantTable'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';

import {
    query,
    getDocs,
    collection,
    where,
} from 'firebase/firestore';
import db from '../../firebase/firebase';
import { useAuth } from "../../contexts/authContext";

function UserHomepage() {
    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if(currentUserRole !== 'user'){
        navigate('/accessDenied', { replace: true }); 
    }
    const { t } = useTranslation();

    const { currentUser } = useAuth();
    const [isMobile, setIsMobile] = useState(false);
    const [selectedOption, setSelectedOption] = useState(t('restaurants'));
    const [searchTerm, setSearchTerm] = useState('');
    const [businessNames, setBusinessNames] = useState([]);
    const [mevMandatarys, setMevMandatarys] = useState([]);
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(currentUser !== null);  // New state for logged in message
    const [remainingDays, setRemainingDays] = useState(7);

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleTouchMove = (e) => {
            e.preventDefault(); // Prevent scrolling
        };

        document.body.style.overflow = 'hidden'; // Disable scrolling
        document.addEventListener('touchmove', handleTouchMove, { passive: false });

        return () => {
            document.body.style.overflow = ''; // Enable scrolling
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    const email = currentUser.email;
    let businessNamesData = [];
    let mevData = [];

    async function queryLookupAndMevMandatarys(email) {
        // const querySnapshotLookup = await getDocs(query(collection(db, "Users"), where('email', '==', currentUser.email)));
        // const lookupData = querySnapshotLookup.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // const defaultStatusUsers = lookupData.filter(user => user.status === "default");
        const querySnapshotInactiveUser = await getDocs(query(collection(db, 'Users'), where('email', '==', email)));
        const userDoc = querySnapshotInactiveUser.docs[0];
        const userData = userDoc.data();
        const cloverMerchantId = userData.merchantId;
        const querySnapshotMevMandatarys = await getDocs(query(collection(db, 'MEVMandatarys'), where('cloverMerchantId', '==', cloverMerchantId)));
        const lookupData = querySnapshotMevMandatarys.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const defaultStatusUsers = lookupData.filter(user => user.status === "default");

        let isLoggedIn = false
        if (defaultStatusUsers.length > 0) {
            defaultStatusUsers.forEach(user => {
                const createdAtTimestamp = user.lastModifiedDate; // Convert Firestore Timestamp to JavaScript Date object
                const date = new Date(createdAtTimestamp.seconds * 1000 + createdAtTimestamp.nanoseconds / 1000000);
                const currentDate = new Date();
                const diffTime = Math.abs(currentDate - date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                const remainingDays = 7 - diffDays;
                setRemainingDays(remainingDays);
            });
            isLoggedIn = true;
        }
        setIsLoggedInMessage(!isLoggedIn);

        lookupData.forEach((item, index) => {
            queryMevMandatarys(item.cloverMerchantId);
        });
    }

    async function queryMevMandatarys(cloverId) {
        const querySnapshot = await getDocs(query(collection(db, "MEVMandatarys"), where('cloverMerchantId', '==', cloverId)));
        const mevMandatarysData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        mevMandatarysData.forEach((item, index) => {
            mevData.push(item)
            businessNamesData.push(item.businessName)
        });
        setMevMandatarys(mevData)
        localStorage.setItem('mevMandatarysData', JSON.stringify(mevData));
        localStorage.setItem('businessData', JSON.stringify(businessNamesData));
        setBusinessNames(businessNamesData);
    }

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                queryLookupAndMevMandatarys(email);
            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };

        const storedBusinessData = localStorage.getItem('businessData');
        const mevMandatarysData = localStorage.getItem('mevMandatarysData');

        if (!mevMandatarysData) {
            fetchBusinessNames();
        } else {
            const parsedBusinessData = JSON.parse(storedBusinessData);
            const parsedMevMandatarysData = JSON.parse(mevMandatarysData);
            setBusinessNames(parsedBusinessData);
            setMevMandatarys(parsedMevMandatarysData)

            var defaultStatusUsers = [] 
            if (parsedMevMandatarysData && parsedMevMandatarysData.length > 0){
                // parsedMevMandatarysData.filter(user => user.status === false);
                defaultStatusUsers = parsedMevMandatarysData.filter(user => user.status === "default");
            }
            let isLoggedIn = false
            if (defaultStatusUsers.length > 0) {
                defaultStatusUsers.forEach(user => {
                    const createdAtTimestamp = user.lastModifiedDate; // Convert Firestore Timestamp to JavaScript Date object
                    const date = new Date(createdAtTimestamp.seconds * 1000 + createdAtTimestamp.nanoseconds / 1000000);
                    const currentDate = new Date();
                    const diffTime = Math.abs(currentDate - date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    const remainingDays = 7 - diffDays;
                    setRemainingDays(remainingDays);
                });
                isLoggedIn = true;
            }
            setIsLoggedInMessage(!isLoggedIn);
    
        }
        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, []);

    const filteredOptions = [...businessNames].filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );
    filteredOptions.unshift("All Businesses");

    useEffect(() => {
        if ( window.location.pathname !== "/dashboard") {
            navigate('/error404')
        }else{
            localStorage.setItem('lastValidPath', window.location.pathname);
        }
    }, [ navigate]);
    // Your table JSX
    return (
        <Fragment>
            {isMobile && (
                <div className="w-full bg-stone-50 justify-start items-start">
                <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />
            
                <div className="w-full flex flex-col justify-start items-start gap-6" style={{ paddingLeft: '1.5rem' }}>
                    <div className="w-full flex justify-start items-center gap-6" style={{ paddingLeft: '1.7rem', paddingRight: '4.9rem' }}>
                        <div className="flex flex-col justify-start items-start gap-12">
                            <div className="flex flex-col justify-start items-start gap-2">
                                <div className="text-sky-900 text-lg font-bold">{t('restaurants')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-white overflow-x-auto">

                    <RestaurantTable mevMandatarysData={mevMandatarys} selectedBusinessName={selectedOption} />
                    </div>
                </div>
            </div>
            
            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex">

                        <Header variableFromPageA={isLoggedInMessage} remainingDays={remainingDays} isAdmin={false} />

                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex" style={{ paddingLeft: '15rem' }}>
                            <div className="self-stretch h-[118px] pl-[17px] pr-[49px] bg-white justify-start items-center gap-6 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                                        <div className="self-stretch text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{t('restaurants')}</div>
                                    </div>
                                </div>
                            </div>
                            <RestaurantTable mevMandatarysData={mevMandatarys} selectedBusinessName={selectedOption} />
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default UserHomepage;
